.electionRow:hover {
    cursor: pointer;
    background-color: orange;
  }

.resultsDiv {
    border: 2px solid black;
    width: 250px;
    height: 250px;
    margin: 10px;
}

.resultsCandidateRow {
    border: 1px solid black;
}

/* .resultsCandidateCol {
    
} */