#app {
  position: relative;
  min-height: 100%;
  min-width: fit-content;
}

.content-wrap {
  padding-bottom: 1px;
  min-height: 100vh;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}

.content-wrapDefault {
  background-color: white;
  color: #222
}

.content-wrapDark {
  background-color: black;
  color: whitesmoke
}

.content-wrapRed {
  background-color: lightpink;
  color: darkred
}

.content-wrapBlue {
  background-color: lightblue;
  color: darkblue
}

.content-wrapGreen {
  background-color: #b9fabd;
  color: darkgreen
}

.content-wrapPurple {
  background-color: lavender;
  color: indigo
}

.content-wrapPatrick {
  background-color: #363a4f; /* Surface0 */
  color: #8087a2;            /* Overlay1 */
}

.content-wrapJensen {
  background-color: black;
  color: #5339FF
}

.songLink {
  font-size: 20px;
  margin: 20px auto;
}

.songColorDefault {
  color: darkBlue;
  text-decoration: none;
}

.songColorDark {
  color: lightblue;
  text-decoration: none;
}

.songColorRed {
  color: darkred;
  text-decoration: none;
}

.songColorBlue {
  color: darkBlue;
  text-decoration: none;
}

.songColorGreen {
  color: darkgreen;
  text-decoration: none;
}

.songColorpurple {
  color: pink;
  text-decoration: none;
}

.songColorPatrick {
  color: #ed8796;   /* Accent: Red */
  text-decoration: none;
}

.songColorJensen {
  color: #008F28;
  text-decoration: none;
}

.songSearch {
  width: 300px;
  font-size: 18px;
  margin-top: 5px;
}

.faFileDefault {
  color: darkBlue;
  margin-right: 10px;
}

.faFileDark {
  color: lightblue;
  margin-right: 10px;
}

.faFileRed {
  color: red;
  margin-right: 10px;
}

.faFileBlue {
  color: blue;
  margin-right: 10px;
}

.faFileGreen {
  color: green;
  margin-right: 10px;
}
.faFilePurple {
  color: magenta;
  margin-right: 10px;
}

.faFilePatrick {
  color: #b7bdf8; /* Lavender */ 
  margin-right: 10px;
}

.faFileJensen {
  color: #008F28;
  margin-right: 10px;
}

.documents-frame-docs {
  width: 100%;
  height: 400px;
  border: 0;
}

.documents-frame-minutes {
  width: 100%;
  height: 600px;
  border: 0;
}

.footer {
  width: 100%;
  margin-top: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex-direction: column;
  height: max-content;
}

.footerDefault {
  background: #222;
  color: white;
}

.footerDark {
  background: #222;
  color: white;
}

.footerRed {
  background: darkred;
  color: lightpink;
}

.footerBlue {
  background: darkblue;
  color: lightblue;
}

.footerGreen {
  background: darkgreen;
  color: lightgreen;
}

.footerPurple {
  background: indigo;
  color: lavender;
}

.footerPatrick {
  background: #181926; /* Crust */
  color: #cad3f5; /* Text */ 
}

.footerJensen {
  background-color: green;
  color: black
}

.footText {
  margin-top: 5px;
  margin-bottom: 5px;
}

.spongeButton {
  font-size: 28px;
  background-color: red;
  color: white;
  width: max-content;
}

.spongeButton:hover {
  cursor: pointer;
}

.meme {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

.thonkTiger {
  height: 200px;
}

.rotate {
  animation: rotation 6s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@media (max-width: 950px) {
  .body {
    z-index: 1;
  }
  
  .content-wrap {
    width: 100vw;
  }

  .admin {
    overflow-y: auto;
  }
}

@media (min-width: 950px) {
  .body {
    padding: 0 2rem;
  }
}

@media (max-width: 450px) {
  .footer {
    margin-top: 1rem;
  }
}