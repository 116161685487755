#president {
  padding-bottom: 7rem;
}

.presidentRow:hover {
  background-color: #FFA300;
  cursor: pointer;
}

table.president {
  table-layout: auto;
  text-align: center;
  width: 97%;
  border-collapse: collapse;
}

table.president th, table.president td{
  border-bottom: 1px solid gray;
  padding-bottom: 1%;
  padding-top: 1%;
}

table.president th{
    color: #3c763d;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9000;
}

.modal-main {
  position:fixed;
  background: white;
  width: 300px;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  padding: 30;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}

.modal-header {
  padding: 2px 16px;
  background-color: #3c763d;
  color: white;
}

.modal-body {
    padding: 20px 16px 0px;
}

.modal-footer {
  padding: 8px 12px;
}

.modalX {
  color: #eee;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.modal-title {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.presidentInput {
  margin-left: 5px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 16px;
}

.presidentLabel {
  font-size: 16px;
}

.modal-btn {
  width: 30%;
  border: none;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 10%;
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}

.btn-save {
  background-color: #007bff;
}

.btn-save:hover {
  background-color: #0069d9;
}

.btn-cancel {
  background-color: #6c757d;
}

.btn-cancel:hover {
  background-color: #5a6268;
}

.presidentWarning {
    color: #a0333a;
    text-align: center;
    font-size: 14px;
    font-style: italic;
    font-weight: 650;
}
