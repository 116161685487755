body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

h1, h2, h3 {
  width: max-content;
}

.clickable {
  cursor: pointer;
  color: darkBlue;
}

.oneline {
  display: flex;
}

.center {
  display: flex;
  justify-content: center;
}

#warning {
  color: red;
}

#disclaimer {
  line-height: 1.75rem;
}

@media (max-width: 950px) {
  #attendance {
    max-width: 100vw;
    text-align: center;
  }
  
  #attendance h1 {
    font-size: 30px;
    margin: 1.25rem auto;
  }
  
  .attendanceh3 {
    font-size: 18px;
    max-width: fit-content;
    margin: 1rem auto;
  }

  #disclaimer {
    font-size: 14px;
  }
}

#feedback {
  padding: 1rem;
}