#Eboard {
  margin: 0 auto;
  text-align: center;
}

#Eboard h1 {
  margin: 1.5rem auto;
}

.eboard-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#eboard-person {
  width: max-content;
  padding: 0 1.5rem 1.5rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
}

#eboard-person img {
  width: 175px;
  height: 225px;
  object-fit: cover;
  margin: auto;
}

#eboard-info {
  margin-top: 1rem;
}

#eboard-info h2 {
  margin: 0 auto;
}

#eboard-info h3 {
  margin: 0.25rem auto;
}

#eboard-info div {
  line-height: 1.6rem;
}

@media (max-width: 450px) {
  .eboard-section {
    justify-content: space-evenly;
  }
  
  #eboard-person {
    padding: 0 1rem 1rem;
  }

  #eboard-person img {
    width: 150px;
    height: 200px;
  }

  #eboard-info h2 {
    font-size: 20px;
  }

  #eboard-info h3 {
    font-size: 16px;
  }

  #eboard-info div {
    font-size: 14px;
  }

  hr {
    margin: 4px;
  }
}