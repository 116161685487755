.theme-list button {
    margin: 0.25rem;
}

button {
    width: 60px;
    height: 36px;
}

.rainbow {
    background: linear-gradient(180deg, #FE0000 16.66%,
		#FD8C00 16.66%, 33.32%,
		#FFE500 33.32%, 49.98%,
		#119F0B 49.98%, 66.64%,
		#0644B3 66.64%, 83.3%,
		#C22EDC 83.3%);
}

.ace {
    background: linear-gradient(180deg, #181818 25%, #A3A3A3 25%, 50%, #FFFFFF 50%, 75%, #800080 75%);
}

.bi {
    background: linear-gradient(180deg, #D60270 40%, #9B4F96 40%, 60%, #0038A8 60%);
}

.enby {
    background: linear-gradient(180deg, #FFF430 25%, #FFFFFF 25%, 50%, #9C59D1 50%, 75%, #181818 75%);
}

.trans {
    background: linear-gradient(180deg, #5BCEFA 20%, #F5A9B8 20%, 40%, #FFFFFF 40%, 60%, #F5A9B8 60%, 80%, #5BCEFA 80%);
}

.pan {
    background: linear-gradient(180deg, #FF218C 33.33%, #FFD800 33.33%, 66.66%, #21B1FF 66.66%);
}

.dark {
    background-color: black;
    color: white;
}

.red {
    background-color: darkred;
    color: pink;
}

.blue {
    background-color: darkblue;
    color: lightblue
}

.green {
    background-color: darkgreen;
    color: lightgreen;
}

.purple {
    background-color: indigo;
    color: lavender
}

body:has(.content-wrapPatrick) {
    font: italic 1.2rem "Fira Sans", serif; /* ;) */
    background-color: #24273a; /* Base */
}

.patrick {
    background-color: #494d64; /* Surface1 */
    color: #ed8796; /* Accent: Red */
}

.jensen {
    background-color: green;
    color: black
}
      
@media (max-width: 450px) {
    #themes {
        text-align: center;
    }

    .theme-list {
        padding: 0.5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}