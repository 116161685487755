.secNav {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.secNavTile {
  background-color: #222;
  border-radius: 25px;
  height: 8rem;
  width: 6.5rem;
  text-decoration: none;
  transition: 0.2s;
  margin: 0.5rem;
}

.secNavTile:hover {
  cursor: pointer;
  opacity: 85%;
  transition: 0.2s;
}

.faSecHome {
  color: #9d9d9d;
  margin-top: 20px;
  margin-left: 35px;
  margin-right: 50px;
  font-size: 25px;
}

.secText {
  color: #9d9d9d;
  text-align: center;
  font-size: 16px;
  padding: 0.5rem;
}

.faAdd {
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
  margin-right: 3px;
}

.faDelete {
  color: red
}

.faAdd:hover, .faDelete:hover, .faEdit:hover {
  cursor: pointer;
}

.faEdit {
  margin-left: 10px;
  margin-right: 10px;
}

.secBarNav {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.secBarText {
  margin: 0px;
  text-decoration: underline;
  color: darkBlue;
}

.secBarLink {
  text-decoration: none;
  color: black;
}

.secBarLink:hover{
  cursor: pointer;
}

.userForm {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.secretaryInput {
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
}

.secretarySubmit {
  width: 200px;
  margin-top: 10px;
  font-size: 16px;
}

.secretaryLabel {
  font-size: 16px;
}

.tableDiv {
  padding-bottom: 30px;
  margin-top: 10px;
}

.userTable {
  border-collapse: collapse;
}

.userRow:hover {
  background-color: #FFA300;
  cursor: pointer;
}

.userHeaderRow:hover {
  background-color: inherit;
  cursor: auto;
}

.userEntry {
  border-bottom: 1px solid black;
}

.userEntrySeparate {
  border-bottom: 1px solid black;
}

.userEntry, .userEntrySeparate {
  width: 250px;
  margin-left: 160px;
  border-right: 1px solid black;
}

.userHeader {
  text-align: left;
  flex: 1 auto;
  width: 250px;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.userTable .userHeader {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}

.userProfile {
  display: flex;
  flex-direction: column;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding-left: 3px;
  padding-right: 3px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.userSearch {
  margin-left: 5px;
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 2s linear infinite;
  margin-top: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.fileInputTag {
  width: auto;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.fileInfo {
  margin-top: 10px;
  margin-bottom: 10px;
}

.fileInput {
  margin-right: 5px;
  margin-left: 5px;
  width: auto;
}

.fileSubmit {
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
}

.marFix {
  margin-left: 55px;
}

.fileDownload {
  margin-right: 10px;
}

.csvDownload {
  align-items: center;
  color: darkBlue;
  text-decoration: underline;
  cursor: pointer;
}

.extraSec {
  border: 2px solid black;
  width: 200px;
}

.tableDiv {
  width: 1200px;
}

@media (max-width: 450px) {
  .tableDiv {
    width: 300px;
    overflow-x: auto;
  }

  #secretary h1 {
    margin: 1rem auto;
  }
}

@media (max-width: 1200px) {
  .tableDiv {
    width: 700px;
  }
}