.chantBody {
    margin-left: 1rem
}

@media (max-width: 950px) {
    #chants {
        margin: 1rem;
        max-width: 90vw;
    }
    
    #chants h3 {
        max-width: 90vw;
    }

    p {
        font-size: 14px;
    }
}