@media (max-width: 950px) {
  #navBar {
    width: 100vw;
    display: flex;
    flex-direction: column;
  }

  .navElement {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
  }
  
  .dropdownMain {
    display: flex;
    flex-direction: column;
    position: sticky;
    width: 100vw;
    z-index: 5;
    padding: 7px 0;
    /* top: 102px; */
  }

  .drop {
    border: 3px solid #9d9d9d;
    background: #9d9d9d;
    border-radius: 30;
    align-self: center;
    justify-self: center;
    margin: 0 !important;
  }

  .drop:hover{
    cursor: pointer;
  }

  .bannerContainer {
    width: 100vw;
  }

  .banner {
    position: sticky;
    width: max-content;
    margin: 0 auto;
    align-self: center;
    max-width: 100vw;
  }
}

@media (min-width: 950px) {
  #navBar {
    display: flex;
  }

  .nav {
    display: flex;
  }

  .dropdownMain {
    display: flex;
    background-color: transparent;
    position: relative;
    padding-bottom: 0;
    top: 0;
  }

  .navUI {
    display: flex;
    flex-wrap: wrap;
  }

  .navLink {
    justify-self: center;
    align-self: center;
  }

  .navElement {
    padding: 10px 18px;
    font-size: 16px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 1rem;
  }

  li {
    float: left;
  }

  li a {
    display: block;
    color: grey;
    text-align: center;
    padding: 0.5rem;
    text-decoration: none;
  }

  .banner {
    height: 100px;
    width: max-content;
    margin: 0;
    padding: 0.5rem;
  }

  .bannerContainer {
    display: flex;
    width: max-content;
    z-index: 5;
  }

  .drop {
    display: none;
    margin: 0px;
    width: 0px;
    height: 0px;
  }
}

.header {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 5;
}

.navUI {
  list-style-type: none;
}

.navElement {
  color: #9d9d9d;
}

.navElementJensen {
  color: #89FF77
}

.navElement:hover {
  background-color: black;
}

.navLink {
  color: #9d9d9d;
  text-decoration: none;
}

.navLink:hover{
  cursor: pointer
}

.banner {
  display: flex;
  filter: hue-rotate(100);
}

.bannerContainerDefault {
  background-color: #222;
}

.bannerContainerDark {
  background-color: #222;
}

.bannerContainerRed {
  background-color: darkred;
}

.bannerContainerBlue {
  background-color: darkblue;
}

.bannerContainerGreen {
  background-color: darkgreen;
}

.bannerContainerPurple {
  background-color: indigo;
}

.bannerContainerPatrick {
  background-color: #181926; /* Crust */
}

.bannerContainerJensen {
  background-color: #008F28;
}

.banner:hover {
  cursor: pointer;
}

.fa {
  margin-right: 8px;
}  

.headerDefault {
  background-color: #222;
}

.headerDark {
  background-color: #222;
}

.headerRed {
  background-color: darkred;
}

.headerBlue {
  background-color: darkblue;
}

.headerGreen {
  background-color: darkgreen;
}

.headerPurple {
  background-color: indigo;
}

.headerPatrick {
  background-color: #181926; /* Crust */
}

.headerJensen {
  background-color: #008F28;
}