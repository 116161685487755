#conductor {
  justify-self: center;
}

#conductorHeading {
  display: flex;
  justify-items: space-between;
}

.conductorTitle {
  margin: 1.5rem auto;
}

.conductorTable {
  width: 100%;
  border-collapse: collapse;
  padding-right: 50px;
}

.conductorHeader {
  font-size: x-large;
  padding-bottom: 15px;
  color: green;
  width: 20%;
}

.conductorData {
  align-self: center;
  text-align: center; 
  align-content: center;
  padding: 5px;
}

.conductorRow {
  border-bottom: 1px solid lightgrey;
  padding: 25px 0px 25px 0px;
}

.songStatusButton {
  font-size: large;
  padding: 5px 15px 5px 15px;
  border: none;
  border-radius: 10px;
  transition: 0.05s;
}

.songStatusButton:hover {
  cursor: pointer;
  opacity: 0.75;
  transition: 0.1s;
}

.statusPrivate {
  background-color: red;
  color: white;
}

.statusPublic {
  background-color: green;
  color: white;
}

.statusAll {
  color: white;
  height: fit-content;
  align-self: center;
}

.allPublic {
  background-color: green;
}

.allPrivate {
  background-color: red;
}

.statusAllHeader {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 2rem;
}

.commentSave {
  justify-content: center;
}

.faSave {
  display: flex;
  flex-wrap: nowrap;
  margin-left: 5px;
}

.faSave:hover {
  cursor: pointer;
}
