@media (min-width: 950px) {
  .login {
    max-width: 100vw;
    background-color: #222;
    background-image: url('../static/banner.jpg');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: top;
    border: 5px solid rgba(0,0,0,.6);
  }

  .signin {
    position: relative;
    top: 0;
    left: 0;
    display: none;
    color: #222;
    z-index: -1;
  }
}

.login {
  position: fixed;
  width: 100%;
  height: 100%;
}

.signin{
  display: flex;
  background: rgba(0,0,0,.7);
  justify-content: center;
  margin: 0px;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.signinText {
  color: white;
  font-size: 25px;
  display: flex;
  justify-content: center;
}

.signinButton {
  border: none;
  background: transparent;
  cursor: pointer;
}

.signinImage {
  width: 250px;
  height: auto;
}

.backupForm {
  display: flex;
  flex-direction: column;
}

.loginInput {
  margin-left: 5px;
  width: 200px;
}

.loginLabel {
  color: white;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 5px;
  font-size: 18px;
}

.loginSubmit {
  margin-top: 10px;
  width: 120px;
  font-size: 18px;
}

.optionLogin {
  color: white;
  font-size: 14px;
  text-decoration: underline;
  display: flex;
  justify-content: center;
}

.optionLogin:hover {
  cursor: pointer;
}
